import React, { useMemo, useState } from 'react';
import { Row } from 'antd';
import { useCartData } from '../../../hooks/order';
import { BundleCard } from './components/BundleCard';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { getBundleProductPrice } from '../../../common/common';

const BundlePlans = (props) => {
  const { serviceabilityCheck, allProducts, bundlePrices, setBundlePrices, productTfn } = props;
  const { plans, mobileProducts } = serviceabilityCheck;
  const { InternetBundles, MobileBundles, mobileProductServRef } = useCartData();

  const [isDescriptionBoxOpened, setIsDescriptionBoxOpened] = useState('-1');

  const sortedInternetData = useMemo(() => {
    if (InternetBundles) {
      return R.sortBy(R.prop('singleProdDisplayOrder'), InternetBundles);
    }
  }, [InternetBundles]);

  const sortedMobileData = useMemo(() => {
    if (MobileBundles) {
      return R.sortBy(R.prop('multiProdDisplayOrder'), MobileBundles);
    }
  }, [MobileBundles]);

  const descriptionBoxClickHandler = (bundleProduct) => setIsDescriptionBoxOpened(isDescriptionBoxOpened === bundleProduct?.bundleCode ? '-1' : bundleProduct?.bundleCode);

  const allBundles = [sortedInternetData, sortedMobileData]
  const showHeading = (i) => {
    if (i === 0 && sortedInternetData?.length !== 0) {
      return `Internet Bundle${sortedInternetData?.length > 1 ? 's' : ''} Available`;
    }
    if (i === 1 && sortedMobileData?.length !== 0) {
      return `Mobile Bundle${sortedMobileData?.length > 1 ? 's' : ''} Available`;
    }
  }
  return (
    <>
      {
        allBundles?.map((bundle, i) => (
          <>
            <div className={'plan-heading d-flex al-center'}>
              {bundle?.length > 0 &&
                <>
                  <span className="y-icon">
                    <i className="fa-solid fa-wifi"></i>
                  </span>{' '}
                  <h3>
                    (<span>{bundle?.length}</span>) {showHeading(i)}
                  </h3>
                </>}
            </div>

            <div className="all-single-plans">
              <Row className="bundle-row">
                {bundle?.map((product) => {
                  return (
                    <BundleCard
                      key={uuid()}
                      plans={plans}
                      bundleProduct={product}
                      allProducts={allProducts}
                      mobileProducts={mobileProducts}
                      bundlePrices={bundlePrices}
                      setBundlePrices={setBundlePrices}
                      setIsDescriptionBoxOpened={descriptionBoxClickHandler}
                      isDescriptionBoxOpened={isDescriptionBoxOpened}
                      productTfn={productTfn}
                      mobileProductServRef={mobileProductServRef}
                      getProductPrice={getBundleProductPrice}
                    />
                  );
                })}

              </Row>
            </div>
          </>
        ))
      }
    </>
  );
};

export default BundlePlans;
