import React from 'react';
import { replaceURLWithHTMLLinks } from '../../common/common';
import { useCartData } from '../../hooks/order';
import { vasProductTermsSpanish } from '../../util/util';
import { getConfigs } from '../../config';

const SHOW_TERMS_CONTRACT = getConfigs().SHOW_TERMS_CONTRACT === 'true';

const TermsInfo = () => {
  const { isInternetAdded, internetPlan, isMobileAdded, vasPlan, spanishLanguagePref, isWhiAdded, isWhiWirelessAdded, generalTerms } =
    useCartData();

  const termsArr = internetPlan?.termsOfSrvc?.split('.  ');

  return (
    <div className="">
      <div className="card-body">
        {isInternetAdded && (
          <div className="terms-info-section m-bottom-0-5">
            {!spanishLanguagePref ? (
              <>
                <h4 className="m-top-1">Internet Service</h4>
                <ul className="terms-info-list ">
                  {isWhiAdded ?
                    <>
                      {termsArr?.map((i, key) => <li key={key}>{i.endsWith('.') ? i : i + '.'}</li>)}
                    </>
                    :
                    <li>
                      Your EarthLink Internet Service requires a [{
                        SHOW_TERMS_CONTRACT ? internetPlan?.contractLength : 12
                      }] month
                      subscription that includes an early termination fee of up to
                      $200.00.
                    </li>
                  }
                </ul>
              </>
            ) : (
              <>
                <h4 className="m-top-1">Internet de EarthLink</h4>
                <ul className="terms-info-list ">
                  {isWhiAdded ?
                    (isWhiWirelessAdded ?
                      <>
                        <li>
                          El Plan de Internet Inalámbrico para el Hogar Ilimitado ofrece 300 gigabytes de datos de alta velocidad para usar solo en la Ubicación del Servicio. Una vez que se agoten los datos de alta velocidad, el Plan de Internet Inalámbrico para el Hogar Ilimitado ofrece datos estándar ilimitados. Los usuarios pueden comprar datos de alta velocidad adicionales en cualquier momento. Los datos comprados que no se utilicen no se transferirán al siguiente ciclo de facturación. Todos los Planes de Internet Inalámbrico para el Hogar no garantizan velocidades de carga o descarga y solo pueden utilizarse en la ubicación del servicio.
                        </li>
                        <li>
                          Para comprar Internet Inalámbrico para el Hogar, usted acepta pagar hoy una tarifa no reembolsable de Procesamiento y Manejo de $79.95 para completar su pedido. Los Planes de Internet Inalámbrico para el Hogar son Planes mensuales pagados por adelantado que requieren activación antes de que el Servicio pueda ser utilizado. Si su Servicio no ha sido activado en un plazo de 10 días a partir de la fecha de entrega del Equipo, su Servicio se activará automáticamente y se le cobrará el primer mes de factura, más los impuestos aplicables, según el método de pago registrado. Si EarthLink no puede activar los Servicios de Internet Inalámbrico para el Hogar, EarthLink se reserva el derecho de cancelar dichos Servicios.
                        </li>
                        <li>
                          Una vez que se consuman todos los datos de alta velocidad en su Plan, EarthLink se reserva el derecho de cambiar la velocidad de su Servicio de Internet Inalámbrico para el Hogar, suspender el Servicio de Internet Inalámbrico para el Hogar, limitar las velocidades de datos y la resolución de video y/o cortar el acceso al Servicio de Internet Inalámbrico para el Hogar.
                        </li>
                        <li>
                          El Plan de Internet Inalámbrico para el Hogar Ilimitado ofrece una función de Filtrado de Contenido opcional que restringe el acceso a contenido dañino y ciertos sitios de transmisión. Al activar este Servicio, reconoce que esta función está deshabilitada por EarthLink, pero puede solicitar que se habilite en cualquier momento una vez que el Servicio esté activado.
                        </li>
                        <li>
                        Al terminar el Servicio, es su responsabilidad devolver el Equipo. Si no devuelve el Equipo, se le cobrará una Tarifa por Equipo No Devuelto. Tendrá 30 días a partir de la terminación de su Servicio para devolver el Equipo, y EarthLink proporcionará una etiqueta de devolución prepagada para facilitar la devolución.
                        </li>
                      </>
                      :
                      <>
                        <li>
                          Para adquirir Wireless Home Internet, usted acepta pagar una tarifa de procesamiento y manejo no-reembolsable de $79.95 para completar su pedido el día de hoy. Los planes de Wireless Home Internet son planes mensuales pagados por adelantado que requieren un pago a la factura del primer mes para activar el servicio.
                        </li>
                        <li>
                          Los planes de Wireless Home Internet ofrecen una asignación fija de datos. El acceso a los datos no es ilimitado. Los datos no utilizados no se transferirán al siguiente ciclo de facturación.
                        </li>
                        <li>
                          Una vez que todos los datos de su plan se consuman, EarthLink se reserva el derecho de cambiar la velocidad de su servicio de Wireless Home Internet, suspender el servicio, reducir la velocidad de los datos y la resolución de videos, y/o cortar el acceso al servicio de Wireless Home Internet.
                        </li>
                        <li>
                          Los usuarios pueden mejorar su plan o comprar datos adicionales en cualquier momento. Los datos comprados y no utilizados no se transferirán al siguiente ciclo de facturación.
                        </li>
                        <li>
                          Los planes de Wireless Home Internet no garantizan velocidades de carga o descarga y solo se pueden utilizar en la ubicación del servicio acordada.
                        </li>
                        <li>
                          Si su servicio no se ha activado dentro de los 10 días posteriores a la fecha de entrega del equipo, EarthLink activará automáticamente el servicio y se cobrará el primer mes de facturación, más los impuestos correspondientes, a través del método de pago principal registrado. Una vez activado, los suscriptores tendrán acceso a Internet. Si EarthLink no puede activar los servicios de Wireless Home Internet, se reserva el derecho de cancelar dichos servicios.
                        </li>
                        <li>
                          Al finalizar el servicio, usted es responsable de devolver el equipo. Si no regresa el equipo, se le cobrará una tarifa por equipo no devuelto. Tendrá 30 días a partir de la terminación de su servicio para devolver el equipo y EarthLink proporcionará una etiqueta de devolución prepagada para facilitar la devolución.
                        </li>
                      </>
                    )
                    :
                    <li>
                      Su Servicio de Internet de EarthLink requiere una
                      suscripción de [{
                        SHOW_TERMS_CONTRACT ? internetPlan?.contractLength : 12
                      }] meses que incluye un cargo por
                      cancelación anticipada desde $200.
                    </li>
                  }
                </ul>
              </>
            )}
          </div>
        )}
        {isMobileAdded && (
          <div className="m-bottom-0-5">
            {!spanishLanguagePref ? (
              <>
                <h4>Mobile Service</h4>
                <ul>
                  <li>
                    EarthLink Mobile requires activation. SIM cards will be
                    cancelled if not activated within 28 days of purchase.
                  </li>
                  <li>
                    Your 4G LTE and 5G speeds require a compatible device with
                    our network. If data limits are exceeded, speeds are reduced
                    to 128 kbps until reset.
                  </li>
                  <li>
                    Your EarthLink Mobile Service is powered by Ultra Mobile,
                    speeds and coverage are not available in all areas, and
                    International long-distance is provided by PLD, LLC, a
                    company not affiliated with EarthLink Mobile.
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h4>Productos Móviles</h4>
                <ul>
                  <li>
                    El servicio de EarthLink Móvil requiere activación. Las
                    tarjetas SIM se cancelarán si no son activadas en 28 días de
                    haberlos comprado.
                  </li>
                  <li>
                    Las velocidades de 4G LTE y 5G requieren un dispositivo que
                    sea compatible con nuestra red. Si usted excede los límites
                    de datos, sus velocidades serán reducidas a 128 kbps hasta
                    que se reinicia su servicio el próximo ciclo mensual.
                  </li>
                  <li>
                    Sus Servicio de EarthLink Móvil está impulsada por Ultra
                    Mobile, y las velocidades y la cobertura no están
                    disponibles en todas las áreas. El servicio internacional
                    está impulsada por PLD, LLC, una compañía que no está
                    afiliada con EarthLink Móvil.
                  </li>
                </ul>
              </>
            )}
          </div>
        )}
        {vasPlan && vasPlan.length > 0 && (
          <div id="termsAccordion">
            <div>
              {vasPlan.map((vasProd, i) => (
                <div key={i}>
                  {vasProd.termsOfSrvc ? (
                    <>
                      {!spanishLanguagePref ? (
                        <h4>{vasProd.serviceName.replace('EarthLink', '')}</h4>
                      ) : (
                        vasProductTermsSpanish.map((terms) => {
                          if (
                            vasProd.serviceName === terms.heading &&
                            terms.serviceTerm !== ''
                          ) {
                            return (
                              <h4 key={terms.heading}>{vasProd.serviceName.replace('EarthLink', '')}</h4>
                            );
                          }
                        })
                      )}
                      <div>
                        {!spanishLanguagePref ? (
                          <ul>
                            {vasProd.termsOfSrvc.split('. ').map((term) => {
                              return term && <li key={term} dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(term) }} />
                            })}
                          </ul>
                        ) : (
                          vasProductTermsSpanish.map((terms) => {
                            if (vasProd.serviceName === terms.heading) {
                              return (
                                <ul key={terms}>
                                  {terms.serviceTerm.split('. ').map((t) => {
                                    return <li key={t} dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(t) }} />
                                  })}
                                </ul>
                              );
                            }
                          })
                        )}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <h4>General</h4>
          {!spanishLanguagePref ? (
            generalTerms?.length > 0 &&
              <>
                <ul className="terms-info-list ">
                  {generalTerms[0]?.termsOfSrvc?.split('. ').map((i) => {
                    return i && <li key={i} dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(i?.endsWith('.') ? i : i + '.' ) }}>
                    </li>
                  })}
                </ul>
              </>
          )
            : (
              <ul>
                <li>
                  A usted le cobráramos una tarifa mensual por el Servicio que
                  será cobrado por anticipado a su método de pago designado.
                </li>
                <li>
                  A final de la terminación de su plan inicial, su Servicio se
                  renovará y reiniciara automáticamente a precios aplicable en el
                  momento.
                </li>
                <li>
                  Usted puede cancelar su Servicio en cualquier momento antes que
                  empieza el siguiente ciclo de facturación para evitar los cargos
                  adicionales. EarthLink no da reembolsos ni créditos por
                  cualquier uso parcial del Servicio durante el mes final antes de
                  cancelar.
                </li>
                <li>
                  Usted puede aprender más sobre nuestros Términos de Servicio,
                  Nuestra Política de Uso Aceptable y nuestra Política de
                  Privacidad en <a href="https://www.earthlink.net/tcs/" target="_blank" rel="noreferrer">Earthlink.net/tcs</a>, y/o <a href= "https://www.earthlink.net/tcs/promotions/" target="_blank" rel="noreferrer">Earthlink.net/tcs/promotions</a> según corresponda.
                </li>
              </ul>
            )}
        </div>
      </div>
    </div>
  );
};

export default TermsInfo;
