import React, { useCallback, useMemo, useState } from 'react'
import { Col, Popover, Select } from 'antd';
import { getValidIntCost, getWirelessServRef } from '../../../../common/common';
import { useCallCenterFlag, useCartData, useMbox, useServiceablitityData, useSessionData } from '../../../../hooks/order';
import * as R from 'ramda';
import { getServicePropsByCode, saveInternetPlan, saveVasPlanInfo, saveWirelessPlanInfo, setMBoxReqObj, setSelectedBundle } from '../../../../store/action/OrderAction';
import { useDispatch } from 'react-redux';
import { EECproductAddToCart, EECproductRemoveFromCart } from '../../../../common/GTMScripts';
import { DashboardOutlined, CustomerServiceOutlined, InfoCircleOutlined, MobileOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { getConfigs } from '../../../../config';

const SHOW_TERMS_CONTRACT = getConfigs().SHOW_TERMS_CONTRACT === 'true';

export const BundleCard = (props) => {
  const dispatch = useDispatch();
  const { serviceabilityCheck } = useServiceablitityData();
  const { mboxRequired } = useMbox();
  const { Option } = Select;
  const { customTFN } = useSessionData();
  const { showCallNow } = useCallCenterFlag();

  const { plans, bundleProduct, mobileProducts, allProducts, setIsDescriptionBoxOpened, isDescriptionBoxOpened, mobileProductServRef, getProductPrice, productTfn } = props;
  const { vasProducts } = serviceabilityCheck;
  const { selectedBundleData, vasPlanInfo, isBundleAdded, internetPlan = {}, wirelessCartInfo, isInternetAdded, serviceTerm } = useCartData();

  const [selectedInternet, setSelectedInternet] = useState(isInternetAdded ? internetPlan : plans[0]);
  const [selectedMobile, setSelectedMobile] = useState(mobileProducts[0])

  const isBundleAddedCart = useMemo(() => {
    return isBundleAdded && selectedBundleData?.bundleCode === bundleProduct?.bundleCode
      ? true
      : false;
  }, [bundleProduct, isBundleAdded, selectedBundleData]);

  const selectedMobServref = useMemo(() => {
    const mobServRef =
      bundleProduct.productsServRef?.includes('mobile*') &&
        mobileProductServRef?.split(',')?.length === bundleProduct.productsServRef?.length
        ? mobileProductServRef?.split(',') || []
        : mobileProducts.length > 0
          ? new Array(bundleProduct.productsServRef?.length).fill(mobileProducts[0]?.servRef).flat()
          : [];
    return mobServRef;
  }, [mobileProductServRef]);

  const getBundleProducts = useCallback(() => {

    const filteredProducts = allProducts.filter(e =>
      bundleProduct.productsServRef?.includes(e.servRef),
    );
    if (bundleProduct.productsServRef?.includes('ipbb*')) {
      filteredProducts.push(selectedInternet);
    }
    if (bundleProduct.productsServRef?.includes('mobile*')) {
      if (selectedMobServref.length > 0) {
        const mobileProduct = selectedMobServref.map(item => {
          return allProducts.find((e) => item === e.servRef) || {};
        });
        const mobProd = R.uniq(mobileProduct);
        const final = mobProd.map(item => {
          return { ...item, quantity: selectedMobServref?.filter(e => e === item?.servRef)?.length || 1 };
        });
        return final;
      }
    } else {
      const final = filteredProducts.map(item => {
        return { ...item, quantity: bundleProduct?.productsServRef?.filter(e => e === item?.servRef)?.length || 1 };
      });
      return final.reverse();
    }
    return [];
  }, [allProducts, bundleProduct, selectedInternet, selectedMobile]);

  const getBundlePrice = useCallback(() => {
    let finalPrice = 0;
    const productsArr = getBundleProducts();
    const productPrice = Number(R.sum(productsArr.map((d) => getValidIntCost(getProductPrice(d, serviceTerm)) * (d?.quantity || 1))).toFixed(2)) || 0;
    finalPrice = Number(productPrice + Number(bundleProduct?.creditAmount)).toFixed(2);
    return finalPrice;
  }, [bundleProduct?.creditAmount, getBundleProducts()]);

  const BundleProducts = useMemo(() => {
    return getBundleProducts();
  }, [getBundleProducts()]);

  const BundleVasProduct = useMemo(() => {
    return BundleProducts.find(e => e?.technology?.toLowerCase() === 'vas') || null;
  }, [BundleProducts]);

  const BundleMobileProduct = useMemo(() => {
    return BundleProducts.find(e => e?.technology?.toLowerCase() === 'mobile') || null;
  }, [BundleProducts]);

  const showAllIPBB = useMemo(() => {
    for (const i of [1, 2, 3, 4].keys()) {
      if (
        bundleProduct[`servLevel${i}`] === '*' &&
        bundleProduct[`servCode${i}`]?.toString()?.toLowerCase() === 'ipbb'
      ) {
        return true;
      }
    }
  }, [bundleProduct]);

  const showAllMob = useMemo(() => {
    for (const i of [1, 2, 3, 4].keys()) {
      if (
        bundleProduct[`servLevel${i}`] === '*' &&
        bundleProduct[`servCode${i}`]?.toString()?.toLowerCase() === 'mobile'
      ) {
        return true;
      }
    }
  }, [bundleProduct, selectedMobServref]);

  const serviceDescPoints = useMemo(() => {
    return selectedInternet?.serviceDesc?.split('. ') || [];
  }, [selectedInternet]);
  const removePreviousProducts = (item) => {
    if (item?.technology?.toLowerCase() === 'dsl') {
      delete mboxRequired[bundleProduct?.servCode];
      dispatch(setMBoxReqObj(mboxRequired));
      EECproductRemoveFromCart(item)
      dispatch(saveInternetPlan({}));
    }
    if (item?.technology?.toLowerCase() === 'vas') {
      let mboxReq = mboxRequired;

      vasPlanInfo?.vasPlan?.map((plan, i) => {
        delete mboxReq[plan.servCode];
        dispatch(setMBoxReqObj(mboxReq));
        vasPlanInfo.vasPlan.splice(i, 1)
        vasPlanInfo?.vasProductCart?.splice(i, 1)
        dispatch(saveVasPlanInfo({
          vasPlan: vasPlanInfo.vasPlan,
          vasProductCart: vasPlanInfo.vasProductCart,
        }));
        EECproductRemoveFromCart(plan);
      })
    }
    if (item?.technology?.toLowerCase() === 'mobile') {
      const selectedWirelessProducts = wirelessCartInfo?.wirelessPlanInfo;
      const mobIndex = selectedWirelessProducts?.findIndex(el => el.servRef === item.servRef);
      selectedWirelessProducts?.splice(mobIndex, 1);
      dispatch(saveWirelessPlanInfo({
        wirelessPlanInfo: selectedWirelessProducts,
        wirelesServeRef: getWirelessServRef(selectedWirelessProducts)
      }));
      EECproductRemoveFromCart(item);
    }
  }

  const createBundle = (mboxReq, productsArr) => {
    const mob = [];
    productsArr.forEach((item) => {
      if (item.technology.toLowerCase() === 'dsl') {
        dispatch(getServicePropsByCode(item.servCode)).then((res) => {
          mboxRequired[item.servCode] = (res.action?.payload?.data?.item?.isMboxReq);
          dispatch(setMBoxReqObj(mboxRequired));
        });
        dispatch(saveInternetPlan(item));
        EECproductAddToCart(item);
      }
      if (item.technology.toLowerCase() === 'vas') {
        let vasVal = 0;

        dispatch(getServicePropsByCode(vasProducts[vasVal].servCode)).then((res) => {
          mboxReq[vasProducts[vasVal].servCode] = (res.action?.payload?.data?.item?.isMboxReq);
          dispatch(setMBoxReqObj(mboxReq));
        });
        let vasProductCartVal;

        if (vasProducts && vasProducts.length) {
          vasProducts.forEach((el, i) => {
            if (el?.servRef === BundleVasProduct?.servRef) {
              vasProductCartVal = i;
            }
          })
        }

        dispatch(saveVasPlanInfo({
          vasPlan: [BundleVasProduct],
          vasProductCart: [vasProductCartVal],
        }));
      }
      if (item?.technology?.toLowerCase() === 'mobile') {
        item.quantity = item.quantity ? item.quantity : 1;
        mob.push(item);
      }
    })
    if (mob.length > 0) {
      dispatch(saveWirelessPlanInfo({
        wirelessPlanInfo: mob,
        wirelesServeRef: getWirelessServRef(mob)
      }));
    }
  }

  const addBundleToCart = () => {

    if (isBundleAddedCart) {
      // if products already added then remove them and clear bundle
      BundleProducts.forEach((item) => {
        removePreviousProducts(item);
      })
      dispatch(setSelectedBundle(null));
    } else {
      let mboxReq = mboxRequired;
      createBundle(mboxReq, BundleProducts);
      dispatch(setSelectedBundle(bundleProduct));
    }
  };

  const handleMobileSelectChange = (ind, isSelected) => {
    setSelectedMobile((prevState) => {
      const updatedMobile = mobileProducts.find(
        (product) => product?.servRef === isSelected
      ) || prevState;
      return { ...prevState, ...updatedMobile };
    });
    if (isSelected) {
      selectedMobServref[ind] = isSelected;
    }
  };

  const popupText = useCallback(item => {
    if (item?.technology?.toLowerCase() === 'vas') {
      return item?.serviceName?.replace('EarthLink ', '')?.split('x')[0];
    }
    if (item?.technology?.toLowerCase() === 'mobile') {
      return `${item?.quantity} * ${item?.serviceName}`;
    }
    return item.serviceName
      .replace('High ', '')
      .replace('Speed ', '')
      .replace('Internet ', '')
      .split('x')[0];
  }, []);

  const showMostPopular = (product) => {
    if (product.topRibbon) {
      return 'mostPop';
    }
    return '';
  }

  const getBulletIcon = (val) => {
    if (val?.toLowerCase()?.includes('mobile')) {
      return <MobileOutlined className="font-blue font-20" />
    } else {
      return <CustomerServiceOutlined className="font-blue font-20" />
    }
  }

  return (
    <Col
      span={12}
      className={`plan-single ${showMostPopular(bundleProduct)}`}
      key={uuid()}
    >
      <div
        className="plan-single-inner"
      >
        <div className="bundle-card-header">
          <h4>${getBundlePrice()}/mo</h4>
          <div className="bundle-name">
            <b>{bundleProduct?.bundleName}</b>{' '}
            {bundleProduct?.category === 'ipbb' && (
              <div className="mx-2">
                {SHOW_TERMS_CONTRACT ? selectedInternet.contractLength : 12} mo Term
              </div>
            )}
            <Popover
              trigger="hover"
              title=""
              overlayStyle={{ maxWidth: '400px', minWidth: '250px' }}
              content={
                <ul className="tol-tip-summery">
                  {BundleProducts?.length > 0 &&
                    BundleProducts?.map(e => {
                      return (
                        <li key={uuid()} className="list-style-none">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                              {popupText(e)}
                            </div>
                            <div>${(Number(e?.quantity) * getValidIntCost(getProductPrice(e, serviceTerm))).toFixed(2)}</div>
                          </div>
                        </li>
                      );
                    })}
                  <li className="list-style-none">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="font-green">Bundle Discount</div>
                      <div className="font-green">
                        ${Math.abs(Number(bundleProduct?.creditAmount)).toFixed(2)}
                      </div>
                    </div>
                  </li>
                </ul>
              }
            >
              <span>
                <InfoCircleOutlined />
              </span>
            </Popover>
          </div>
        </div>

        <ul className="list-bundle">
          {showAllIPBB ? (
            <li className="icon-list product-name">
              <div className="icons-list-details">
                <DashboardOutlined className="font-blue font-20" />
              </div>
              <p>
                <Select className={`bundle-select ${isBundleAddedCart ? 'disable-bundle-text' : ''}`}
                  bordered={false}
                  size="small"
                  defaultValue={selectedInternet.servRef}
                  onChange={value => setSelectedInternet(R.find(R.propEq('servRef', value), plans) || selectedInternet)}
                  disabled={isBundleAddedCart}
                >
                  {plans?.map(item => {
                    return (
                      <Option value={item.servRef} key={uuid()}>
                        {item?.downstreamSpd / 1000}Mbps (${item.price})
                      </Option>
                    );
                  })}
                </Select>
              </p>
            </li>
          ) : null}
          {showAllMob
            ? bundleProduct?.productsServRef &&
            bundleProduct?.productsServRef.map((i, ind) => {
              return (
                <li key={ind} className="icon-list product-name">
                  <span className="icons-list-details">
                    <MobileOutlined className="font-blue font-20" />
                  </span>
                  <Select
                    className="bundle-select"
                    bordered={false}
                    size="small"
                    defaultValue={selectedMobServref.length ? selectedMobServref[ind] : mobileProducts[0].servRef}
                    onChange={value => handleMobileSelectChange(ind, value)}
                    disabled={isBundleAddedCart}
                  >
                    {mobileProducts.map((item) => {
                      return (
                        <Select.Option value={item.servRef} key={item.servRef}>
                          {item.serviceName} ({getProductPrice(item, serviceTerm)})
                        </Select.Option>
                      );
                    })}
                  </Select>
                </li>
              );
            })
            : null}
          {bundleProduct.servBullet2 && bundleProduct?.servBullet2?.toLowerCase() !== 'null' && (
            <li className="icon-list">
              <div className="icons-list-details">
                {getBulletIcon(bundleProduct?.servBullet2)}
              </div>
              <p className="bundle-point">
                {bundleProduct?.servBullet2 === bundleProduct?.servBullet3 ? `(2) ${bundleProduct?.servBullet2}` : bundleProduct?.servBullet2}
              </p>
            </li>
          )}
          {bundleProduct?.servBullet3 && bundleProduct?.servBullet2 !== bundleProduct?.servBullet3 &&
            <li className="icon-list">
              <div className="icons-list-details">
                {getBulletIcon(bundleProduct?.servBullet3)}
              </div>
              <p>
                {bundleProduct?.servBullet3}
              </p>
            </li>
          }


        </ul>
        <div>
          {!showCallNow ?
            <div className="down-cart d-flex al-center j-between">
              <button
                className={`blue-button ${isBundleAdded && 'grey-button'}`} style={{ padding: '7px 10px' }}
                disabled={isBundleAdded && !isBundleAddedCart}
                onClick={() => {
                  addBundleToCart();
                }}
              >
                {isBundleAddedCart
                  ? 'Remove'
                  : 'Add'}{' '}
                <i
                  className={`fa-solid ${isBundleAddedCart ? 'fa-times' : 'fa-solid fa-cart-shopping'}`}
                ></i>
              </button>

              <div className="learnmore-total">
                <span
                  className="learnmore"
                  onClick={() => setIsDescriptionBoxOpened(bundleProduct)}
                >
                  {isDescriptionBoxOpened === bundleProduct?.bundleCode && isDescriptionBoxOpened !== '-1' ?
                    <span>See Less</span> :
                    <span>See More</span>}
                  {' '}
                </span>
              </div>
            </div>
            :
            <div className="agent-call-button">
              <a href={`tel:${customTFN ? customTFN : productTfn}`} className="blue-button elnk-tfn-link"> <i className="fa-solid fa-mobile-screen-button"></i>  <span className="phone-span elnk-tfn-tag">{customTFN ? customTFN : productTfn} </span></a>
            </div>
          }

          {isDescriptionBoxOpened === bundleProduct?.bundleCode && isDescriptionBoxOpened !== '-1' && (
            <div className="below-list">
              <ul className="list-bundle">
                <p className="learnmore-heading">Internet</p>
                {serviceDescPoints.map((point) => {
                  return (
                    <li key={uuid()} className="icon-list">
                      {point}
                    </li>
                  );
                })}

                {BundleVasProduct ?
                  <> <p className="learnmore-heading">{BundleVasProduct?.techDetail}</p>
                    {BundleVasProduct?.serviceInfo?.split('.')?.filter(item => item !== '').map(point => <li key={uuid()}>{point}</li>)}
                  </> : null}

                {BundleMobileProduct ?
                  <> <p className="learnmore-heading">Mobile</p>
                    {BundleMobileProduct?.serviceInfo?.split('.')?.filter(item => item !== '').map(point => <li key={uuid()}>{point}</li>)}
                  </> : null}

              </ul>
            </div>
          )}
        </div>
      </div>
    </Col>
  )
}
